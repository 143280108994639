import base from './baseHTTPService';
import { ApiResponse } from "../models/ApiResponse";
import { EventDto } from "../models/events/EventDto";
import { API_EVENTS_URL, API_LATEST_EVENTS_URL } from '../constants';
import { LatestEventDto } from '../models/events/LatestEventDto';

export const getEventById = async (eventId: number): Promise<ApiResponse<EventDto>> => {
    try {
        const response = await base.get<ApiResponse<EventDto>>(`${API_EVENTS_URL}/${eventId}`); // Replace with your actual backend endpoint
        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        throw error;
    }
}

export const getAllEvents = async (): Promise<ApiResponse<EventDto[]>> => {
    try {
        const response = await base.get<ApiResponse<EventDto[]>>(API_EVENTS_URL); // Replace with your actual backend endpoint
        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        throw error;
    }
}

export const getNextEvent = async (): Promise<ApiResponse<EventDto[]>> => {
    try {
        const response = await base.get<ApiResponse<EventDto[]>>(`${API_EVENTS_URL}/next`); // Replace with your actual backend endpoint
        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        throw error;
    }
}

export const getCompletedEvents = async (): Promise<ApiResponse<EventDto[]>> => {
    try {
        const response = await base.get<ApiResponse<EventDto[]>>(`${API_EVENTS_URL}/completed`); // Replace with your actual backend endpoint
        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        throw error;
    }
}

export const getMostRecentEvent = async (): Promise<ApiResponse<EventDto | null>> => {
    try {
        const response = await base.get<ApiResponse<EventDto | null>>(`${API_EVENTS_URL}/most-recent`); // Replace with your actual backend endpoint
        return response.data;
    } catch (error) {
        console.error('Error fetching events:', error);
        throw error;
    }
}

export const getLatestEventHttpClientService = async (): Promise<ApiResponse<LatestEventDto>> => {
    try {
      const response = await base.get<ApiResponse<LatestEventDto>>(`${API_LATEST_EVENTS_URL}`);
      return response.data;
    } catch (error) {
      console.error('Error retrieving latest event:', error);
      throw error;
    }
  }

