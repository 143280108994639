import { EventDto } from "../../models/events/EventDto";
import CreateTicketsModal from "../tickets/CreateTicketsModal";
import { useState } from "react";
import { EventStatus } from "../../models/enums/EventStatus";
import { EventReservationStatus } from "../../models/enums/EventReservationStatus";

interface UpcomingEventProps {
  event: EventDto;
}

const UpcomingEvent: React.FC<UpcomingEventProps> = ({ event }) => {
  const [createTicketModalOpen, setCreateTicketModalOpen] = useState(false);
  return (
    <>
      <div className="img-container">
        <img className="img" src={event.flyerUri} alt="" />
        <div className="overlay">
          <div className="container">
            <div className="row">
              <h4 style={{ width: '100%', textAlign: 'center' }}>{`${event.eventName}`.toUpperCase()}</h4>
            </div>
            <div className="row" style={{ marginTop: 20 }}>
              <span style={{ width: '100%', textAlign: 'center' }}><i className="fa-solid fa-location-dot"></i> {event.location}</span>
            </div >
            <div className="row">
              <span style={{ width: '100%', textAlign: 'center' }}><i className="fa-solid fa-calendar-days"></i> {event.date}</span>
            </div>
            <div className="row">
              <span style={{ width: '100%', textAlign: 'center' }}><i className="fa-solid fa-clock"></i> {event.time}</span>
            </div>
            {
              event.status !== EventStatus.Completed &&
              <div className="row" style={{ marginTop: 20 }}>
                <div className="col order-button-container">
                  <button 
                    className="btn button rounded-0 order-button"
                    disabled={event.reservationStatus === EventReservationStatus.Closed} 
                    onClick={() => setCreateTicketModalOpen(true)}>
                      {event.reservationStatus === EventReservationStatus.Closed ? 'Reservation Closed' : 'Reserve Now'}
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <CreateTicketsModal open={createTicketModalOpen} event={event} onClose={() => setCreateTicketModalOpen(false)} />
    </>
  );
}

export default UpcomingEvent;