import { ErrorDto } from "../../models/ErrorDto";
import { appActions } from "../reducers";

const initialState = {
  loading: false,
  error: new ErrorDto()
};

const appReducer = (state = initialState, action: any): (typeof initialState) => {
  switch (action.type) {
    case appActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case appActions.SET_ERROR:
      return {
        ...state,
        error: action.payload as ErrorDto,
      };
    default:
      return state;
  }
};

export default appReducer;