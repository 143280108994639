import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RefundPolicy: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="text-white">
      <section className="section">
        <div className="r-container">
          <div className="d-flex flex-lg-row flex-column-reverse gap-3">
            <div className="col col-lg-12">
              <div className="d-flex flex-column gap-3">
                <h3 className="font-2 lh-1 fw-bold" style={{ fontSize: '48px' }}>REFUND POLICY</h3>
                <p>At YO Entertainment, we strive to provide exceptional experiences for our customers. In the event that circumstances require a change to an event, we understand that you may require a refund. Please read our refund policy carefully to understand your options.</p>
                <h5 className="lh-1 ls-2 fw-bold font-1">Eligibility for Refunds:</h5>
                <ul>
                  <li>•	Refunds are only eligible when the date, venue, or lead artist of an event is changed. If any of these changes occur, you may request a refund for your ticket purchase.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Refund Process:</h5>
                <ul>
                  <li>•	All refund requests must be submitted through our official channels within 7 days of the announced change to the event.</li>
                  <li>•	Refunds will be processed to the same bank account used for the original purchase. Please allow up to 14 days for the refund to be reflected in your account after the event has concluded.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">How to Request a Refund:</h5>
                <ul>
                  <li>•	To request a refund, please contact our customer support team at [contact email or phone number]. Provide your ticket information and details of the event change.</li>
                  <li>•	Our team will review your request and process the refund if it meets the eligibility criteria outlined in this policy.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Non-Refundable Circumstances:</h5>
                <ul>
                  <li>•	Refunds will not be provided for reasons other than changes to the event date, venue, or lead artist. This includes, but is not limited to, personal scheduling conflicts or changes in preferences.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Additional Terms:</h5>
                <ul>
                  <li>•	YO Entertainment reserves the right to amend or modify this refund policy at any time without prior notice.</li>
                  <li>•	This refund policy is subject to the terms and conditions outlined in our Terms of Service.</li>
                </ul>

                <p>If you have any questions or concerns regarding our refund policy, please don't hesitate to contact us. We appreciate your understanding and cooperation.p&gt;
                </p><p>Thank you for choosing YO Entertainment for your entertainment needs!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RefundPolicy;