import { useNavigate } from "react-router-dom";
import { EventDto } from "../../models/events/EventDto";
import { Swiper, SwiperSlide } from "swiper/react";

import './css/UpcomingEvents.css';
import UpcomingEvent from "./UpcomingEvent";

interface UpcomingEventsProps {
  nextEvents: EventDto[];
}

const UpcomingEvents: React.FC<UpcomingEventsProps> = ({ nextEvents }) => {
  const navigate = useNavigate();

  return (
    <section className="section bg-secondary-dark">
      <div className="r-container">
        <div className="d-flex flex-column">
          <h5 className="accent-color lh-1 ls-2 fw-bold font-1">Events</h5>
          <h3 className="lh-1 fw-bold" style={{ color: 'white', fontSize: '48px' }}>Upcoming Events</h3>
          <div className="row row-cols-1 row-cols-lg-2 my-3">
            <div className="col" style={{ padding: 0 }}>
              <div className="d-flex flex-column">
                <div className="col">
                  <Swiper
                    breakpoints={{
                      640: { slidesPerView: 1, spaceBetween: 15 },
                      768: { slidesPerView: 2, spaceBetween: 15 },
                      1024: { slidesPerView: 2, spaceBetween: 15 }
                    }}
                    loop={true}
                    style={{ width: '100%', color: 'white' }}>
                    {
                      nextEvents.map((event, index) => (
                        <SwiperSlide key={index}>
                          <UpcomingEvent event={event} />
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </div>
              </div>
            </div>
            <div className="col">
              <p style={{ color: 'white' }}>
                Welcome to the YO Entertainment Upcoming Events page! Here, you can explore all the incredible live music events we have lined up for you. From chart-topping artists to emerging talents, our calendar is packed with unforgettable performances. Browse through the schedule, discover your favorite acts, and reserve your tickets in advance to ensure you don’t miss out. Experience the thrill of live music with YO Entertainment—book your tickets now and get ready to rock!
              </p>
              <div className="d-flex flex-lg-row flex-column gap-lg-5 gap-3 py-3">
                <button onClick={() => navigate('/events')}
                  className="px-4 py-2 btn button accent-color button-outline rounded-0 fw-bold font-1 hover-transform see-all-events-button">
                  See all events
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UpcomingEvents;