import base from './baseHTTPService';
import { ApiResponse } from "../models/ApiResponse";
import { API_EVENTS_URL } from '../constants';
import { CreateTicketDto } from '../models/tickets/CreateTicketDto';
import { TicketGroupDto } from '../models/tickets/TicketGroupDto';

export const createTicket = async (eventId: number, payload: CreateTicketDto): Promise<ApiResponse<TicketGroupDto>> => {
    try {
        const response = await base.post<ApiResponse<TicketGroupDto>>(`${API_EVENTS_URL}/${eventId}/ticket-groups`, payload); // Replace with your actual backend endpoint
        return response.data;
    } catch (error) {
        console.error('Error creating tickets:', error);
        throw error;
    }
}