export class SendCustomerMessageRequest {
  message: string;
  subject: string;
  email: string;
  phoneNumber: string;
  lastName: string;
  firstName: string;

  constructor(
    message: string = '',
    subject: string = '',
    email: string = '',
    phoneNumber: string = '',
    lastName: string = '',
    firstName: string = ''
  ) {
    this.message = message;
    this.subject = subject;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.lastName = lastName;
    this.firstName = firstName;
  }
}