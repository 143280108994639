export const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_EVENTS_URL = '/api/v1/events';
export const API_MESSAGES_URL = '/api/v1/messages';
export const API_LATEST_EVENTS_URL = '/api/v1/latest-event';

export const VALIDATION_MESSAGES = {
  NAME_NOT_EMPTY: "cannot be empty",
  NAME_MIN_LENGTH: "minimum length is six",
  NAME_MAX_LENGTH: "maximum length is ten",
  EMAIL_NOT_VALID: "Email is not valid.",
  PHONE_NOT_VALID: "Phone number is not valid.",
  NAME_ONLY_LETTERS: "should contain only letters",
  TICKET_TYPE_ID_NOT_VALID: "Ticket type is required",
  IDENTITY_FIELD_NOT_VALID: "Identification field cannot be empty",
  QUANTITY_NOT_VALID: "Quantity should be greater than one"
}