import { BaseEntityDto } from "../BaseEntityDto";
import { CustomerDto } from "../customers/CustomerDto";
import { TicketStatus } from "../enums/TicketStatus";
import { EventDto } from "../events/EventDto";
import { TicketTypeDto } from "../events/TicketTypeDto";

export class TicketGroupDto extends BaseEntityDto {
    eventId: number = 0;
    event: EventDto = new EventDto();
    // barcode: string = '';
    status: TicketStatus = TicketStatus.PaymentPending;
    paymentConfirmationUri: string = '';
    comments: string = '';
    ticketType: TicketTypeDto = new TicketTypeDto();
    customer: CustomerDto = new CustomerDto();
}