import React, { useState } from "react";
import { EventDto } from "../../models/events/EventDto";
import { Col, Form, Row, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { TicketGroupDto } from "../../models/tickets/TicketGroupDto";
import { RequiredMark } from "antd/es/form/Form";

interface PaymentConfirmationProps {
  event: EventDto
  uploadProgress: number;
  ticketGroup: TicketGroupDto;
  uploadFile: (options: any) => void;
}

const PaymentConfirmation: React.FC<PaymentConfirmationProps> = ({ event, uploadFile, ticketGroup, uploadProgress }) => {
  const [form] = Form.useForm();
  const [paymentConfirmationFileList, setPaymentConfirmationFileList] = useState<any[]>([]);
  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');
  const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredMarkType(requiredMarkValue);
  };
  const paymentConfirmationFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handlePaymentConfirmationChange = (info: any) => {
    setPaymentConfirmationFileList(info.fileList);
  };
  return (
    <Row>
      <Col span={24} style={{ marginTop: 15 }}>
      <Form 
        form={form} 
        layout="vertical"
        initialValues={{ requiredMarkValue: requiredMark }}
        onValuesChange={onRequiredTypeChange}>
      <Form.Item label="Ticket Payment Confirmation" labelAlign="left">
        <Form.Item name="draggerPaymentConfirmation" getValueFromEvent={paymentConfirmationFile} noStyle>
          <Upload.Dragger
            name="ticketPaymentConfirmation"
            data={{ id: event.id }}
            customRequest={uploadFile}
            multiple={false}
            fileList={paymentConfirmationFileList}
            onChange={handlePaymentConfirmationChange}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Support only single file uploads.</p>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
      </Form>
      </Col>
    </Row>
  );
}

export default PaymentConfirmation;