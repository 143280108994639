import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }: any) => {
  return (
    <div className="app-container">
      <Header />
      <main className="flex-grow-1" style={{ backgroundColor: '#0d0d0d' }}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default Layout;