import { Link } from "react-router-dom";

const Footer = ({ children }: any) => {
  return (
    <footer className="site-footer">
      <div className="r-container">
        <div className="row">
          <div className="col-sm-12 col-md-5">
            <h6>About</h6>
            <p className="text-justify">YO Entertainment invites you to explore a realm of vibrant and captivating
              entertainment experiences. Our dedication lies in curating and delivering exceptional events
              that celebrate the diverse tapestry of music, culture, and entertainment. We invite you to be
              part of our journey as we redefine the very essence of entertainment, creating lasting
              impressions through unique and unforgettable moments that resonate with every attendee. </p>
          </div>
          <div className="col-sm-12 col-md-1"></div>

          <div className="col-xs-6 col-md-3">
            <h6>Policies</h6>
            <ul className="footer-links">
              <li><Link className="footer-link" to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link className="footer-link" to="/terms-and-conditions">Terms and Conditions</Link></li>
              <li><Link className="footer-link" to="/refund-policy">Refund Policy</Link></li>
            </ul>
          </div>

          <div className="col-xs-6 col-md-3">
            <h6>Quick Links</h6>
            <ul className="footer-links">
              <li><Link className="footer-link" to={'/'}>Home</Link></li>
              <li><Link className="footer-link" to="/events">Events</Link></li>
              <li><Link className="footer-link" to="/?section=about">About Us</Link></li>
              <li><Link className="footer-link" to="/?section=contact">Contact Us</Link></li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
      <div className="r-container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">Copyright &copy; 2024 All Rights Reserved by YO Entertainment
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li><a className="facebook" href="https://www.facebook.com/yoentertainment.event" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook-f"></i></a></li>
              <li><a className="instagram" href="https://www.instagram.com/yoentertainment.events" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></a></li>
              <li><a className="tiktok" href="https://www.tiktok.com/@yoentertainment.events" target="_blank" rel="noreferrer"><i className="fa-brands fa-tiktok"></i></a></li>
              <li><a className="youtube" href="https://www.youtube.com/@yoentertainment-n3k" target="_blank" rel="noreferrer"><i className="fa-brands fa-youtube"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;