import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TermsAndConditions: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="text-white">
      <section className="section">
        <div className="r-container">
          <div className="d-flex flex-lg-row flex-column-reverse gap-3">
            <div className="col col-lg-12">
              <div className="d-flex flex-column gap-3">
                <h3 className="font-2 lh-1 fw-bold" style={{ fontSize: '48px' }}>TERMS AND CONDITIONS</h3>
                <p>Welcome to YOEntertainment! These terms and conditions outline the rules and regulations for the use of our website and services.</p>
                <p>By accessing this website or using our services, you agree to abide by these terms and conditions. If you disagree with any part of these terms and conditions, please do not use our website or services.</p>
                <h5 className="lh-1 ls-2 fw-bold font-1">Intellectual Property</h5>
                <ul>
                  <li>•   The content, logos, and trademarks displayed on this website are the intellectual property of YO Entertainment and are protected by copyright laws. You may not use, reproduce, or distribute any content from this website without our prior written consent.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">User Content</h5>
                <ul>
                  <li>•	You may submit content, such as comments or reviews, to our website. By submitting content, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, and distribute your content for any purpose.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Prohibited Activities</h5>
                <ul>
                  <li>•	You agree not to engage in any unlawful, abusive, or fraudulent activities while using our website or services. This includes, but is not limited to, hacking, spamming, or distributing malware.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Privacy</h5>
                <ul>
                  <li>•	Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Limitation of Liability</h5>
                <ul>
                  <li>•	YO Entertainment shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our website or services.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Indemnification</h5>
                <ul>
                  <li>•	You agree to indemnify and hold harmless Yo Entertainment, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, or expenses arising out of your use of our website or services.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Governing Law</h5>
                <ul>
                  <li>•	These terms and conditions shall be governed by and construed in accordance with the laws of Sri Lanka, without regard to its conflict of law provisions.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Changes to Terms and Conditions</h5>
                <ul>
                  <li>•	YO Entertainment reserves the right to update or modify these terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on this page.</li>
                </ul>

                <p>By using our website or services, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you have any questions or concerns, please contact us. </p>
                <p>Thank you for choosing YO Entertainment!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TermsAndConditions;