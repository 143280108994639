export class ErrorDto {
  error: boolean;
  message: string;

  constructor(
    error: boolean = false,
    message: string = 'An error occurred in upstream services, please try again'
  ) {
    this.error = error;
    this.message = message;
  }
}