import { Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { CreateTicketDto } from "../../models/tickets/CreateTicketDto";
import { useState } from "react";
import { RequiredMark } from "antd/es/form/Form";
import { EventDto } from "../../models/events/EventDto";
import { EventReservationType } from "../../models/enums/EventReservationType";

interface TicketDataProps {
  event: EventDto,
  ticketTypes: any[],
  reservation: CreateTicketDto,
  setReservation: (reservation: CreateTicketDto) => void
}

const TicketData: React.FC<TicketDataProps> = ({ event, ticketTypes, reservation, setReservation }) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');
  const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredMarkType(requiredMarkValue);
  };
  return (
    <Row>
      <Form form={form}
        layout="vertical"
        style={{ marginTop: 10 }}
        initialValues={{ requiredMarkValue: requiredMark }}
        onValuesChange={onRequiredTypeChange}>
        <Row gutter={10}>
          <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
            <Form.Item label={event.reservationType === EventReservationType.Tickets ? 'Ticket Type' : 'Seat Type'} required tooltip="This is a required field">
              <Select defaultValue={reservation.ticketTypeId === 0 ? null : reservation.ticketTypeId} onChange={e => setReservation({ ...reservation, ticketTypeId: Number(e) })} options={ticketTypes} />
            </Form.Item>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item label="Quantity" required tooltip="This is a required field">
              <InputNumber min={1} max={10} defaultValue={1} style={{ width: '100%' }}
                value={reservation.quantity} onChange={e => setReservation({ ...reservation, quantity: e ?? 1 })} />
            </Form.Item>
          </Col>
        </Row>


        <Row gutter={10}>
          <Col span={24}><Divider style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold' }} plain>YOUR DETAILS</Divider></Col>
        </Row>


        <Row gutter={10} style={{ marginTop: 10 }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item label="Name" required tooltip="This is a required field">
              <Input
                value={reservation.customer.name}
                onChange={e => setReservation({
                  ...reservation,
                  customer: {
                    ...reservation.customer,
                    name: e.target.value
                  }
                })} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item label="Email" required tooltip="This is a required field">
              <Input
                value={reservation.customer.email}
                onChange={e => setReservation({
                  ...reservation,
                  customer: {
                    ...reservation.customer,
                    email: e.target.value
                  }
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item label="Phone" required tooltip="This is a required field">
              <Input
                value={reservation.customer.phone}
                onChange={e => setReservation({
                  ...reservation,
                  customer: {
                    ...reservation.customer,
                    phone: e.target.value
                  }
                })} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
            <Form.Item label="Address" tooltip="This is a required field">
              <Input
                value={reservation.customer.address}
                onChange={e => setReservation({
                  ...reservation,
                  customer: {
                    ...reservation.customer,
                    address: e.target.value
                  }
                })} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
            <Form.Item label="NIC/Passport" required tooltip="This is not a required field">
              <Input
                value={reservation.customer.identificationField}
                onChange={e => setReservation({
                  ...reservation,
                  customer: {
                    ...reservation.customer,
                    identificationField: e.target.value
                  }
                })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Comments" tooltip="This is not a required field">
              <TextArea rows={2} value={reservation.comments} onChange={e => setReservation({ ...reservation, comments: e.target.value })} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Row>
  )
};

export default TicketData;