import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/home/Home';
import Event from './components/events/Event';
import Layout from './components/layout/Layout';
import Events from './components/events/Events';
import { connect } from "react-redux";
import { Spin } from "antd";
import RefundPolicy from "./components/home/RefundPolicy";
import PrivacyPolicy from "./components/home/PrivacyPolicy";
import TermsAndConditions from "./components/home/TermsAndConditions";
import NotFound from "./components/404/NotFound";
import { ErrorDto } from "./models/ErrorDto";
import { Backdrop } from '@mui/material';

interface AppProps {
  error?: ErrorDto;
  loading?: boolean;
}

const App: React.FC<AppProps> = ({ error, loading }) => {
  const ErrorPage = () => {
    return (
      <NotFound message={'An error occurred in upstream services, please reload the page.'} />
    )
  }

  const isError = error!.error;

  return (
    <>
      {
        <Backdrop style={{ zIndex: '9000' }} open={loading!}>
          <Spin spinning={loading} size="large" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99999, display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
        </Backdrop>
      }
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={isError ? <ErrorPage /> : <Home />} />
            <Route path="events" element={isError ? <ErrorPage /> : <Events />} />
            <Route path="events/:eventId" element={isError ? <ErrorPage /> : <Event />} />
            <Route path="refund-policy" element={isError ? <ErrorPage /> : <RefundPolicy />} />
            <Route path="privacy-policy" element={isError ? <ErrorPage /> : <PrivacyPolicy />} />
            <Route path="terms-and-conditions" element={isError ? <ErrorPage /> : <TermsAndConditions />} />
            <Route path="*" element={<NotFound message={"UNKNOWN PAGE, PLEASE GO BACK TO HOME"} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

const mapStateToProps = (state: any): any => ({
  error: state.app.error,
  loading: state.app.loading,
});

export default connect(mapStateToProps, null)(App);
