import { Col, Divider, Row, Steps } from "antd";
import { EventPaymentConfirmationOption } from "../../models/enums/EventPaymentConfirmationOption";
import { EventDto } from "../../models/events/EventDto";
import { CheckCircleFilled, CheckCircleOutlined, DollarOutlined, FormOutlined } from "@ant-design/icons";

interface ReservationStepsProps {
  event: EventDto,
  currentStep: number,
  setCurrentStep: (step: number) => void
}

const ReservationSteps: React.FC<ReservationStepsProps> = ({ event, currentStep, setCurrentStep }) => {
  return (
    <>
            {
        event.paymentConfirmationRequirement === EventPaymentConfirmationOption.NotRequired &&
        <Row gutter={10}>
          <Col span={24}>
            <Divider style={{ marginTop: 10, marginBottom: 5, fontWeight: 'bold' }} plain></Divider>
          </Col>
        </Row>
      }

      {
        (event.paymentConfirmationRequirement === EventPaymentConfirmationOption.Required ||
          event.paymentConfirmationRequirement === EventPaymentConfirmationOption.Optional) &&
        <Row style={{ marginTop: 15 }}>
          <Steps
            current={currentStep}
            items={[
              {
                title: 'Make Reservation',
                icon: currentStep === 0 ? <FormOutlined /> : <CheckCircleFilled />,
                status: 'process'
              },
              {
                title: 'Payment Confirmation',
                icon: currentStep <= 1 ? <DollarOutlined /> : <CheckCircleFilled />,
                status: 'process'
              },
              {
                title: 'Successful',
                icon: currentStep === 2 ? <CheckCircleFilled /> : <CheckCircleOutlined />,
                status: 'finish'
              }
            ]}
          />
        </Row>
      }
    </>
  );
}

export default ReservationSteps;