/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';

const Header = ({ children }: any) => {
  const location = useLocation();
  const { pathname } = location;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentSection = searchParams.get('section');
  const [open, setOpen] = useState(false);

  // console.log({ pathname });

  const handleNavigation = (path: string) => {
    if (pathname === '/') {
      setSearchParams({ ...searchParams, section: path });
    }
    else {
      navigate(`/?section=${path}`);
    }
    setOpen(false);
  }

  return (
    <div className="sticky-top py-2 bg-dark-color" style={{ backgroundColor: "#000000" }}>
      <div className="r-container">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid" style={{ paddingLeft: 0 }}>
            <div className="logo-container">
              <Link to="/" style={{ padding: 0, margin: 0 }}>
                <img src="image/logo.gif" alt="" className="img-fluid" />
              </Link>
            </div>
            <button className="navbar-toggler"
              type="button"
              style={{ color: '#ff934e' }}
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={open}
              onClick={() => setOpen(!open)}
              aria-label="Toggle navigation">
              <span className="fa-solid fa-bars-staggered"></span>
            </button>
            <Collapse in={open}>
              <div className="navbar-collapse pt-lg-0 pt-3" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-3 justify-content-center" >
                  <li className="nav-item">
                    <button className={`nav-link${pathname === '/' ? ' active' : ''}`} style={{ color: 'white' }} onClick={() => { navigate('/'); setOpen(false); }} >Home</button>
                  </li>
                  <li className="nav-item">
                    <button className={`nav-link${pathname === '/events' ? ' active' : ''}`} style={{ color: 'white' }} aria-current="page" onClick={() => { navigate('/events'); setOpen(false); }} >Events</button>
                  </li>
                  <li className="nav-item">
                    <button className={`nav-link${currentSection === 'about' ? ' active' : ''}`} style={{ color: 'white' }} onClick={() => handleNavigation('about')}>About</button>
                  </li>
                  <li className="nav-item">
                    <button className={`nav-link${currentSection === 'contact' ? ' active' : ''}`} style={{ color: 'white' }} onClick={() => handleNavigation('contact')}>Contact</button>
                  </li>
                </ul>
              </div>
            </Collapse>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;