import { Button, Modal } from "antd";

interface ConfirmModalProps {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  text: string;
  title: string;
  customButton: boolean;
  customButtonLabel: string;
  customButtonAction: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ open, handleOk, handleCancel, text, title, customButton, customButtonLabel, customButtonAction }) => {
  return (
    <Modal
      open={open}
      title={title}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      onClose={() => handleCancel()}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          {
            customButton &&
            <Button onClick={() => customButtonAction()}>{customButtonLabel}</Button>
          }
          <OkBtn />
        </>
      )}
    >
      <p>{text}</p>
    </Modal>
  );
}

export default ConfirmModal;