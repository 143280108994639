import base from './baseHTTPService';
import { ApiResponse } from "../models/ApiResponse";
import { SendCustomerMessageRequest } from "../models/messages/SendCustomerMessageRequest";
import { API_MESSAGES_URL } from '../constants';

export const sendCustomerMessage = async (payload: SendCustomerMessageRequest): Promise<ApiResponse<any>> => {
    try {
        const response = await base.post<ApiResponse<any>>(`${API_MESSAGES_URL}`, payload); // Replace with your actual backend endpoint
        return response.data;
    } catch (error) {
        console.error('Error creating tickets:', error);
        throw error;
    }
}