import React from "react";
import { EventDto } from "../../models/events/EventDto";
import { Col, Row, Spin, Image } from "antd";

interface EventInformationProps {
  event: EventDto
}

const EventInformation: React.FC<EventInformationProps> = ({ event }) => {
  return (
    <>
      <Row>
        <Image
          src={event.bannerUri}
          style={{ width: '100%', aspectRatio: '18/5' }}
          fallback={process.env.PUBLIC_URL + '/image/rb_2775.png'}
          placeholder={
            <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spin size="large" />
            </div>
          }
        />
      </Row>
      <Row gutter={10} style={{ marginTop: 10 }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span><i className="fa-solid fa-location-dot" /> {event.location}</span>
          <span><i className="fa-solid fa-calendar-days" /> {event.date}</span>
          <span><i className="fa-solid fa-clock" /> {event.time}</span>
        </Col>
      </Row>
      <Row>
        <Col span={24} className='event-description-card'>
          <div>{event?.eventDescription}</div>
        </Col>
      </Row>
    </>
  )
}

export default EventInformation;