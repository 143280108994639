/* eslint-disable react-hooks/exhaustive-deps */
import './css/Event.css'

import { useEffect, useState } from "react";
import { EventDto } from "../../models/events/EventDto";
import { getEventById } from "../../services/eventServices";
import { useParams } from "react-router-dom";
import { EventReservationType } from '../../models/enums/EventReservationType';
import CreateTicketsModal from '../tickets/CreateTicketsModal';
import { appActions } from '../../store/reducers';
import { connect } from 'react-redux';
import { Image, Spin } from 'antd';
import { EventStatus } from '../../models/enums/EventStatus';
import { ErrorDto } from '../../models/ErrorDto';
import { EventReservationStatus } from '../../models/enums/EventReservationStatus';

interface EventProps {
  setError?: (error: ErrorDto) => void
  setLoading?: (loading: boolean) => void
}

const Event: React.FC<EventProps> = ({ setError, setLoading }) => {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<EventDto>(new EventDto());
  const [buyTicketModalOpen, setBuyTicketModalOpen] = useState(false);

  useEffect(() => {
    void getEvent();
  }, [eventId]);

  const getEvent = async (): Promise<void> => {
    setLoading!(true);
    try {
      const apiResponse = await getEventById(Number(eventId));
      if (apiResponse.success) {
        setError!(new ErrorDto());
        setEvent(apiResponse.data);
      } else {
        setError!(new ErrorDto(true));
      }
    } catch (error) {
      setError!(new ErrorDto(true));
    }
    setLoading!(false);
  }

  const ticketTypes = event?.ticketTypes ?? [];
  // const defaultFlyerUri = process.env.PUBLIC_URL + '/image/dummy-img-900x500.jpg';

  const shareToFacebook = () => {
    const url = window.location.href; // Get the current page URL
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookShareUrl, '_blank', 'noopener,noreferrer');
  };

  const shareToTwitter = () => {
    const url = window.location.href;
    const text = "Check out this event!";
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
    window.open(twitterShareUrl, '_blank', 'noopener,noreferrer');
  };
  
  const shareToWhatsApp = () => {
    const url = window.location.href;
    const text = "Check out this event!";
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}%20${encodeURIComponent(url)}`;
    window.open(whatsappShareUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className="r-container" style={{ marginTop: 10 }}>
        <div className="d-flex flex-lg-row flex-column-reverse gap-3">
          <div className="col col-lg-3 textured-background justify-content-center position-relative" style={{ marginBottom: 15 }}>
              <div className="p-5">
                {/* <div className="texture-overlay" style={{ minHeight: '0px !important' }}></div> */}
                <div className="text-center" style={{ zIndex: 2, width: '100%' }}>
                  <h3 style={{ color: 'white' }}>LOOKING FOR YOUR NEXT SHOW?</h3>
                  <h5 className="accent-color fw-bold font-1" style={{ marginTop: 25 }}>GET YOUR TICKETS NOW</h5>
                  <div style={{ color: 'white', marginTop: 25 }} >
                    Don't miss out on our exciting events. Book your tickets today for an unforgettable experience!
                  </div>
                  {
                    event.status !== EventStatus.Completed &&
                    <button 
                      disabled={event.reservationStatus === EventReservationStatus.Closed}
                      className="transparent-button" 
                      onClick={() => setBuyTicketModalOpen(true)} style={{ marginTop: 25 }}>
                      {
                        event.reservationStatus === EventReservationStatus.Closed ? 'RESERVATION CLOSED' : 
                        event.reservationType === EventReservationType.Tickets ? 
                          'GET TICKETS' : 
                          'RESERVE TABLES'
                      }
                    </button>
                  }
                </div>
              </div>
          </div>
          <div className="col col-lg-9" style={{ marginBottom: 15 }}>
            <div className="d-flex flex-column" style={{ color: 'white' }}>
              <h4 className="event-name">{event?.eventName}</h4>
              <Image 
                src={event.bannerUri} 
                style={{ height: '300px', width: '100%' }} 
                fallback={process.env.PUBLIC_URL + '/image/rb_2775.png'}
                placeholder={
                  <div style={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Spin size="large" />
                  </div>
                }
                />
              <div className='event-description'>{event?.eventDescription}</div>
              <div className="row" style={{ marginBottom: 20, marginTop: 20 }}>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <table className="borderless">
                        <tr>
                          <td><i className="fa-solid fa-location-dot"
                            style={{ width: '23px', textAlign: 'center', position: 'relative', right: '5px' }}></i>
                          </td>
                          <td width="250">{event?.location}</td>
                        </tr>
                        <tr>
                          <td><i className="fa-solid fa-calendar-days"
                            style={{ width: '23px', textAlign: 'center', position: 'relative', right: '5px' }}></i>
                          </td>
                          <td width="250">{event?.date}</td>
                        </tr>
                        <tr>
                          <td><i className="fa-solid fa-clock"
                            style={{ width: '23px', textAlign: 'center', position: 'relative', right: '5px' }}></i>
                          </td>
                          <td width="250">{event?.time}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <table className="borderless">
                    <thead></thead>
                    <tbody>
                    {
                      ticketTypes.map((ticketType, index) => (
                        <tr key={index}>
                          <td width="150">{ticketType.name}</td>
                          <td width="150" style={{ textAlign: 'right'}}>{ticketType.price.toFixed(2)} {event.currency}</td>
                        </tr>
                      ))
                    }
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
              </div>

              <div className="border-bottom border-white my-1"></div>

              <div className="d-flex flex-row gap-3 align-items-center" style={{ marginTop: 20 }}>
                <span className="accent-color">Share :</span>
                <div className="social-container">
                  <div className="social-item">
                    <button 
                      onClick={shareToFacebook}
                      className="btn share-button" 
                      data-platform="facebook">
                      <i className="fa-brands fa-facebook"></i>
                    </button>
                  </div>
                  <div className="social-item">
                    <button
                      onClick={shareToTwitter} 
                      className="btn share-button" 
                      data-platform="twitter">
                      <i className="fa-brands fa-twitter"></i>
                    </button>
                  </div>
                  <div className="social-item">
                    <button 
                      onClick={shareToWhatsApp}
                      className="btn share-button" 
                      data-platform="whatsapp">
                      <i className="fa-brands fa-whatsapp"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateTicketsModal
        open={buyTicketModalOpen}
        event={event}
        onClose={() => setBuyTicketModalOpen(false)}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch: any): any => ({
  setError: (error: ErrorDto) => dispatch({ type: appActions.SET_ERROR, payload: error }),
  setLoading: (loading: boolean) => dispatch({ type: appActions.SET_LOADING, payload: loading }),
});

export default connect(null, mapDispatchToProps)(Event);