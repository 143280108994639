import './css/NotFound.css';

import { Button, Col, Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";

interface NotFoundProps {
  message: string;
}

const NotFound: React.FC<NotFoundProps> = ({ message }) => {
  const navigate = useNavigate();
  return (
    <div style={{ height: '50vh', display: 'flex', flexDirection: 'column' }}>
      <Row style={{ margin: 20 }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            size="large"
            type="primary" 
            className="btn button rounded-0 home-button"
            style={{ width: 150 }}
            onClick={() => navigate('/')}>
              <b>HOME</b>
          </Button>
        </Col>
      </Row>
      <Row style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large"></Spin><span style={{ marginLeft: 10, color: 'white', fontSize: '18px' }}><b>{message}</b></span>
      </Row>
    </div>
  );
}

export default NotFound;