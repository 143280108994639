import './css/CreateTicketsModal.css';

import { Modal, Row, Button, Result } from 'antd';
import { useState } from 'react';
import { EventDto } from '../../models/events/EventDto';
import { EventReservationType } from '../../models/enums/EventReservationType';
import { CreateTicketDto } from '../../models/tickets/CreateTicketDto';
import { createTicket } from '../../services/ticketServices';
import { appActions } from '../../store/reducers';
import { connect } from 'react-redux';
import { TicketTypeDto } from '../../models/events/TicketTypeDto';
import { EventPaymentConfirmationOption } from '../../models/enums/EventPaymentConfirmationOption';
import TicketData from './TicketData';
import EventInformation from './EventInformation';
import ReservationSteps from './ReservationSteps';
import PaymentConfirmation from './PaymentConfirmation';
import axios from 'axios';
// import { API_BASE_URL } from '../../constants';
import { TicketGroupDto } from '../../models/tickets/TicketGroupDto';
import { validateReservationData } from '../../utils/validationUtils';
import ConfirmModal from '../common/ConfirmModal';
import { isNullOrEmptyOrWhitespace } from '../../utils/stringUtils';
import { ApiResponse } from '../../models/ApiResponse';
import { REACT_APP_API_BASE_URL } from '../../constants';

interface CreateTicketsModalProps {
  open: boolean;
  event: EventDto;
  onClose: any;
  setLoading?: (loading: boolean) => void;
}

const CreateTicketsModal: React.FC<CreateTicketsModalProps> = ({ open, onClose, event, setLoading }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [reservation, setReservation] = useState<CreateTicketDto>(new CreateTicketDto());
  const [ticketGroup, setTicketGroup] = useState<TicketGroupDto>(new TicketGroupDto());
  const [openValidationModal, setOpenValidationModal] = useState(false);

  const ticketTypes = event.ticketTypes.length > 0
    ? event.ticketTypes.map((ticketType: TicketTypeDto) => ({ value: ticketType.id, label: `${ticketType.name} - ${ticketType.price.toFixed(2)} ${event.currency}` }))
    : [];

  const createTicketRequest = async () => {
    const validationState = validateReservationData(reservation);
    console.log({ validationState });
    if (validationState.status === false) {
      Modal.error({
        title: 'Error!',
        content: 'Details are either empty or invalid, please check and try again.'
      });
      return;
    }

    setLoading!(true);
    await createTicket(event.id, reservation)
      .then((response) => {
        if (response.success) {
          if (event.paymentConfirmationRequirement === EventPaymentConfirmationOption.NotRequired) {
            setReservation(new CreateTicketDto());
            success();
          } else {
            setTicketGroup(response.data);
            setCurrentStep(1);
          }
        } else {
          error();
        }
      }).catch((error) => {
        console.error('Error creating tickets:', error);
      }).finally(() => setLoading!(false));
  }

  const uploadFile = async (options: any): Promise<void> => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (percent === 100) {
          setTimeout(() => { setUploadProgress(0); }, 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };

    let uploaderUrl = `${REACT_APP_API_BASE_URL}/api/v1/events/${event.id}/ticket-groups/${ticketGroup.id}/payment-confirmation`;

    fmData.append('file', file);
    setLoading!(true);
    await axios.patch(
      uploaderUrl,
      fmData,
      config
    ).then((response) => {
      const ticketCreated = response.data as ApiResponse<TicketGroupDto>;
      setTicketGroup(ticketCreated.data);
      setLoading!(false);
      setCurrentStep(2);
      onSuccess('Ok');
    }).catch((err) => {
      setLoading!(false);
      onError({ err });
    });
  };

  const success = () => {
    Modal.success({
      content: 'Your reservation has been successfully created!, Please check your email for the ticket details. Make sure to send us the payment confirmation.',
      afterClose: onClose()
    });
  };

  const error = () => {
    Modal.error({
      title: 'Failed to create reservation',
      content: 'An error occurred while creating the reservation. Please try again later.',
      afterClose: onClose()
    });
  };

  const closeButtonAction = () => {
    if (event.paymentConfirmationRequirement === EventPaymentConfirmationOption.Required && ticketGroup.id !== 0 && isNullOrEmptyOrWhitespace(ticketGroup.paymentConfirmationUri)) {
        setOpenValidationModal(true);
        return;
    }

    setReservation(new CreateTicketDto());
    setTicketGroup(new TicketGroupDto());
    setCurrentStep(0);
    onClose();
  }

  const onCloseAnywayAction = () => {
    setReservation(new CreateTicketDto());
    setTicketGroup(new TicketGroupDto());
    setCurrentStep(0);
    onClose();
  }

  return (
    <>
      <Modal
        open={open}
        onCancel={closeButtonAction}
        width={800}
        footer={
          <Row style={{ alignContent: 'flex-end', alignItems: 'flex-end', float: 'right' }}>
            {ticketGroup.id === 0 ?
              <Button className='button-submit' style={{ height: 40 }} onClick={async () => await createTicketRequest()}>
                {
                  event.paymentConfirmationRequirement === EventPaymentConfirmationOption.Required ? 'NEXT STEP' : 'RESERVE TICKETS'
                }
              </Button> : <></>
            }
            {
              currentStep === 1 &&
              <Button className='button-submit' style={{ height: 40 }} onClick={() => closeButtonAction()}>
                CLOSE
              </Button>
            }
          </Row>
        }
        bodyProps={{ style: { paddingRight: 10, height: 'calc(100vh - 250px)', overflowX: 'hidden', overflowY: 'auto', maxHeight: 'calc(100vh - 200px)', scrollbarWidth: 'thin' } }}
        title={`${event.reservationType === EventReservationType.Tickets ? 'BUY TICKETS' : 'RESERVE SEATS'} - ${event.eventName.toUpperCase()}`}
      >
        <EventInformation event={event} />
        <ReservationSteps event={event} currentStep={currentStep} setCurrentStep={setCurrentStep} />
        {currentStep === 0 &&
          <TicketData event={event} reservation={reservation} setReservation={setReservation} ticketTypes={ticketTypes} />
        }
        {
          currentStep === 1 &&
          <PaymentConfirmation event={event} uploadFile={uploadFile} uploadProgress={uploadProgress} ticketGroup={ticketGroup} />
        }
        {
          currentStep === 2 &&
          <Result
            status="success"
            title="You have Successfully Created your Reservation!"
            subTitle={`Reservation Id: ${ticketGroup.id.toString().padStart(5, '0')}`}
            extra={[
              <Button type="primary" key="console" onClick={() => closeButtonAction()}>
                <b>CLOSE</b>
              </Button>
            ]}
          />
        }
        {/* </div> */}
      </Modal>
      <ConfirmModal
        open={openValidationModal}
        handleOk={() => setOpenValidationModal(false)}
        handleCancel={() => setOpenValidationModal(false)}
        text=''
        title='Payment confirmation must be uploaded before closing.'
        customButton={true}
        customButtonLabel='Close Anyway'
        customButtonAction={() => { setOpenValidationModal(false); onCloseAnywayAction(); }}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch: any): any => ({
  setLoading: (loading: boolean) => dispatch({ type: appActions.SET_LOADING, payload: loading }),
});

export default connect(null, mapDispatchToProps)(CreateTicketsModal);