import { EventReservationStatus } from "../enums/EventReservationStatus";
import { EventReservationType } from "../enums/EventReservationType";
import { EventStatus } from "../enums/EventStatus";
import { BaseEntityDto } from "../BaseEntityDto";
import { TicketTypeDto } from "./TicketTypeDto";
import { EventPaymentConfirmationOption } from "../enums/EventPaymentConfirmationOption";

export class EventDto extends BaseEntityDto {
    eventName: string = '';
    eventDescription: string = '';
    location: string = '';
    date: string = '';
    time: string = '';
    expectedAttendance: number = 0;
    flyerUri: string = '';
    bannerUri: string = '';
    videoUri: string = '';
    currency: string = '';
    status: EventStatus = EventStatus.Pending;
    reservationType: EventReservationType = EventReservationType.Tickets;
    reservationStatus: EventReservationStatus = EventReservationStatus.Open;
    paymentConfirmationRequirement: EventPaymentConfirmationOption = EventPaymentConfirmationOption.NotRequired;
    ticketTypes: TicketTypeDto[] = [];
}