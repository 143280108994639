import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PrivacyPolicy: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="text-white">
      <section className="section">
        <div className="r-container">
          <div className="d-flex flex-lg-row flex-column-reverse gap-3">
            <div className="col col-lg-12">
              <div className="d-flex flex-column gap-3">
                <h3 className="font-2 lh-1 fw-bold" style={{ fontSize: '48px' }}>PRIVACY POLICY</h3>
                <p>At YO Entertainment, we are committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us when using our services or visiting our website.
                </p>
                <h5 className="lh-1 ls-2 fw-bold font-1">Information Collection and Use:</h5>
                <ul>
                  <li>•	We may collect personal information such as your name, email address, phone number, and payment details when you register for an event, make a purchase, or subscribe to our newsletter. </li>
                  <li>•	We use this information to provide you with our services, process transactions, communicate with you, and send you relevant updates and promotions.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Cookies and Tracking Technologies:</h5>
                <ul>
                  <li>•	We use cookies and similar tracking technologies to enhance your experience on our website, analyze trends, and improve our services.</li>
                  <li>•	These technologies may collect information such as your IP address, browser type, device type, and browsing behavior.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Information Sharing and Disclosure:</h5>
                <ul>
                  <li>•	We do not sell, trade, or rent your personal information to third parties for marketing purposes.</li>
                  <li>•	We may share your information with trusted service providers who assist us in operating our website, conducting business, or servicing you, as long as they agree to keep this information confidential.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Data Security:</h5>
                <ul>
                  <li>•	We implement a variety of security measures to safeguard your personal information against unauthorized access, disclosure, alteration, or destruction.</li>
                  <li>•	However, please note that no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Children's Privacy:</h5>
                <ul>
                  <li>•	Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take steps to remove that information from our systems.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Changes to this Privacy Policy:</h5>
                <ul>
                  <li>•	We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website.</li>
                  <li>•	We encourage you to review this Privacy Policy periodically for any updates.</li>
                </ul>

                <h5 className="lh-1 ls-2 fw-bold font-1 mt-2">Contact Us:</h5>
                <ul>
                  <li>•	If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please contact us. </li>
                </ul>

                <p>By using our services or visiting our website, you agree to the terms of this Privacy Policy. Thank you for trusting YOEntertainment with your personal information.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PrivacyPolicy;