import './css/EventCard.css';

import React from "react";
import { EventDto } from "../../models/events/EventDto";
import { useNavigate } from 'react-router-dom';
import { Image, Spin } from 'antd';
import { EventStatus } from '../../models/enums/EventStatus';
import { EventReservationStatus } from '../../models/enums/EventReservationStatus';

interface EventCardProps {
  event: EventDto;
  showDescription: boolean;
}

const EventCard: React.FC<EventCardProps> = ({ event, showDescription }: EventCardProps) => {
  const navigate = useNavigate();
  // const defaultFlyerUri = process.env.PUBLIC_URL + '/image/dummy-img-400x400.jpg';

  return (
    <div className="col mb-3" style={{ color: 'white' }}>
      <div className="d-flex flex-column gap-3">
        <Image
          style={{ width: '100%', height: '375px', cursor: 'grab' }}
          src={event.flyerUri}
          fallback={process.env.PUBLIC_URL + '/image/rb_2775.png'}
          placeholder={
            <div style={{ height: '375px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Spin size="large" />
            </div>
          }
          preview={false}
          onClick={() => navigate(`/events/${event.id}`)} />
        <div className="shadow d-flex justify-content-center align-items-center"></div>
      </div>
      <div className="d-flex flex-column gap-2">
        <h4 className='event-title' style={{ fontWeight: 'bold', textTransform: 'uppercase' }} >{event.eventName}</h4>
        {
          showDescription &&
          <div className='event-description-event-card'>{event.eventDescription}</div>
        }
        {event.status !== EventStatus.Completed && 
          <button className='reserve-button' style={{ width: '70%' }} 
            onClick={() => navigate(`/events/${event.id}`)}  >
            {event.reservationStatus === EventReservationStatus.Closed ? 'RESERVATION CLOSED' : 'RESERVE NOW'}
          </button>}
      </div>
    </div>
  )
}

export default EventCard;