import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import appReducers from './slices/appReducers';

const rootReducer = combineReducers({
  app: appReducers
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});