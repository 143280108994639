/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSearchParams } from 'react-router-dom';
import './css/Home.css';
import { useEffect, useRef, useState } from 'react';
import { appActions } from '../../store/reducers';
import { connect } from 'react-redux';
import { EventDto } from '../../models/events/EventDto';
import { getCompletedEvents, getLatestEventHttpClientService, getNextEvent } from '../../services/eventServices';
import UpcomingEvents from '../events/UpcomingEvents';
import RecentEvents from '../events/RecentEvents';
import Portfolio from './Portfolio';
import { SendCustomerMessageRequest } from '../../models/messages/SendCustomerMessageRequest';
import { sendCustomerMessage } from '../../services/messageServices';
import { message as antMessage, FloatButton } from 'antd';
import { ErrorDto } from '../../models/ErrorDto';
import { ArrowUpOutlined } from '@ant-design/icons';
import { LatestEventDto } from '../../models/events/LatestEventDto';

interface HomeProps {
  setError?: (error: ErrorDto) => void;
  setLoading?: (loading: boolean) => void;
}

const Home: React.FC<HomeProps> = ({ setError, setLoading }) => {
  const [searchParams] = useSearchParams();

  const about = useRef(null);
  const contact = useRef(null);

  const currentSection = searchParams.get('section');

  const [nextEvents, setNextEvents] = useState<EventDto[]>([]);
  const [completedEvents, setCompletedEvents] = useState<EventDto[]>([]);
  const [recentEvent, setRecentEvent] = useState<LatestEventDto | null>(null);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [messageApi, contextHolder] = antMessage.useMessage();
  const [message, setMessage] = useState<SendCustomerMessageRequest>(new SendCustomerMessageRequest());


  const bannerUrls = [
    //process.env.PUBLIC_URL + '/image/1.jpg',
    process.env.PUBLIC_URL + '/image/2.jpg',
    process.env.PUBLIC_URL + '/image/3.jpg',
    process.env.PUBLIC_URL + '/image/background-parallax.jpg',
    process.env.PUBLIC_URL + '/image/hero.jpg'
  ];

  useEffect(() => {
    if (currentSection) {
      if (currentSection === 'about') {
        scroll(about);
      }
      if (currentSection === 'contact') {
        scroll(contact);
      }
    }
  }, [currentSection])

  useEffect(() => {
    void getInitialData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % bannerUrls.length);
        setFadeOut(false);
      }, 800); // Match the duration of the CSS transition
    }, 10000); // Change banner every 10 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [bannerUrls.length]);

  const scroll = (elementRef: any) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth',
    })
  };

  const success = (successMessage: string) => {
    messageApi.open({
      type: 'success',
      content: successMessage,
    });
  };

  const getInitialData = async (): Promise<void> => {
    setLoading!(true);
    try {
      const nextEventApiResponse = await getNextEvent();
      const recentEventApiResponse = await getLatestEventHttpClientService();
      const completedEventsApiResponse = await getCompletedEvents();
      setNextEvents(nextEventApiResponse.data);
      setRecentEvent(recentEventApiResponse.data);
      setCompletedEvents(completedEventsApiResponse.data);
      if (!nextEventApiResponse.success || !recentEventApiResponse.success || !completedEventsApiResponse.success) {
        setError!(new ErrorDto(true));
      } else {
        setError!(new ErrorDto());
      }
    } catch (e) {
      setError!(new ErrorDto(true));
    }
    setLoading!(false);
  }

  const sendMessage = async (): Promise<void> => {
    setLoading!(true);
    await sendCustomerMessage(message)
      .then((res) => {
        if (res.success) {
          setMessage(new SendCustomerMessageRequest());
          success('Your message has been successfully sent.');
          setError!(new ErrorDto());
        } else {
          setError!(new ErrorDto(true));
        }
        setLoading!(false);
      }).catch(() => {
        console.error('Error sending message');
        setError!(new ErrorDto(true));
        setLoading!(false);
      })
  }

  return (
    <>
      {contextHolder}
      <section className={`section position-relative hero-section banner ${fadeOut ? 'fade-out' : ''}`} style={{ height: '90vh', backgroundImage: `url(${bannerUrls[currentBannerIndex]})` }}>
        <div className="image-overlay"></div>
        <div className="r-container position-relative w-100 h-100" style={{ zIndex: 2 }}>
          <div className="d-flex flex-column justify-content-center align-items-center text-center h-100">
            <h5 className="font-1 fs-3 ls-2 fw-bold fade-in-zoom" id="text1" style={{ color: 'white' }}>Experience the Unforgettable!</h5>
            <div className="typewriter-container">
              <h1 className="lh-1 fw-bold">
                <span className="fade-in-zoom" id="text2" style={{ color: 'white' }}>STEP INTO THE WORLD OF</span>
                <br />
                <span style={{ color: '#ffa76f' }} className="fade-in-zoom" id="text3">YO ENTERTAINMENT</span>
              </h1>
            </div>
            <p className=" mx-auto fs-3 ls-2 fw-bold fade-in-zoom delay2" style={{ maxWidth: '768px', color: 'white' }}
              id="text4">
              Where We Redefine the Entertainment Industry!
            </p>
          </div>
        </div>
      </section>

      {nextEvents.length > 0 && <UpcomingEvents nextEvents={nextEvents} />}

      {recentEvent && <RecentEvents recentEvent={recentEvent} />}

      <Portfolio about={about} completedEvents={completedEvents} />

      <section className="section position-relative bg-secondary-dark">
        <div className="texture-overlay" id="texture-overlay-img"></div>
        <div className="r-container position-relative" style={{ zIndex: 2 }}>
          <div className="d-flex flex-column text-center">
            <h5 className="accent-color lh-1 ls-2 fw-bold font-1">Services</h5>
            <h3 className="font-2 lh-1 fw-bold" style={{ color: 'white', marginBottom: 20 }}>What Services We Offer ?</h3>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 text-start w-100 my-3">
              <div className="col mb-3">
                <div className="d-flex flex-row gap-3">
                  <span className="accent-color" style={{ fontSize: '3rem' }}>
                    <img src="./image/palette.png" style={{ height: 50 }} alt='' />
                  </span>
                  <div style={{ color: 'white' }}>
                    <h5 className="font-2 fw-bold">Event Design</h5>
                    <div className='descriptions'>Craft breathtaking event atmospheres, paying meticulous attention to every subtle detail.</div>
                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="d-flex flex-row gap-3">
                  <span className="accent-color" style={{ fontSize: '3rem' }}>
                    <img src="./image/megaphone.png" style={{ height: 50 }} alt='' />
                  </span>
                  <div style={{ color: 'white' }}>
                    <h5 className="font-2 fw-bold">Event Marketing</h5>
                    <div className='descriptions'>Boost event attendance through strategic marketing campaigns across various channels.</div>
                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="d-flex flex-row gap-3">
                  <span className="accent-color" style={{ fontSize: '3rem' }}>
                    <img src="./image/coworker.png" style={{ height: 50 }} alt='' />
                  </span>
                  <div style={{ color: 'white' }}>
                    <h5 className="font-2 fw-bold">Event Coordinating</h5>
                    <div className='descriptions'>Ensures flawless event execution with expert logistical planning and on-site management.</div>
                  </div>
                </div>
              </div>
              <div className="col mb-3">
                <div className="d-flex flex-row gap-3">
                  <span className="accent-color" style={{ fontSize: '3rem' }}>
                    <img src="./image/business.png" style={{ height: 50 }} alt='' />
                  </span>
                  <div style={{ color: 'white' }}>
                    <h5 className="font-2 fw-bold">Corporate Events</h5>
                    <div className='descriptions'>Tailors corporate gatherings to strengthen relationships and achieve business objectives.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="r-container">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col col-lg-5">
              <div className="d-flex flex-column">
                <h5 className="accent-color lh-1 ls-2 fw-bold font-1">Our Story</h5>
                <h3 className="lh-1 fw-bold" style={{ color: 'white', fontSize: '48px' }}>Story Behind Yo Entertainment</h3>
                <img src="image/yohan-perera-mobile.png" alt="" className="img-fluid yope-img-mobile" />
                <div className='story-descriptions'>
                  Meet YOhan Perera, the entrepreneur behind YO Entertainment's dynamic presence in Sri Lanka's entertainment landscape. Founded in 2017, YO Entertainment swiftly became synonymous with exceptional events and unforgettable experiences.
                </div>
                <div className='story-descriptions'>
                  With a passion for excellence and an eye for talent, YOhan has curated a diverse portfolio of events, coordinating artists across the country to deliver captivating performances that leave audiences spellbound.
                </div>
                <div className='story-descriptions'>
                  From vibrant musical events to high-profile corporate gatherings, YOhan's meticulous attention to detail and unwavering dedication ensure that every event is a resounding success. His ability to bring together top-tier talent and create seamless collaborations has earned him the respect and admiration of clients and collaborators alike.
                </div>
                <div className='story-descriptions'>
                  But YOhan's impact extends beyond the stage. As a leader in the entertainment industry, he is committed to nurturing and supporting local artists, providing them with platforms to showcase their talent and reach new audiences.
                </div>
                <div className='story-descriptions'>
                  Currently based in British Columbia, Canada, join YOhan Perera and YO Entertainment on a journey of creativity, innovation, and endless possibilities. With YOhan at the helm, the future of entertainment in Sri Lanka shines brighter than ever before.
                </div>
              </div>
            </div>
            <div className="col col-lg-7 mt-5">
              <img src="image/yohan-perera.png" alt="" className="img-fluid yope-img" />
            </div>
          </div>
        </div>
      </section>

      <section className="section position-relative" id="contact" ref={contact}>
        <div className="bg-overlay"></div>
        <div className="r-container  position-relative" style={{ zIndex: 2 }}>
          <div className="d-flex flex-lg-row flex-column-reverse gap-5">
            <div className="col">
              <div className="h-100 d-flex flex-column">

                <form className="d-flex flex-column w-100 needs-validation mb-3 form" id="contactForm" >
                  <div className="row row-cols-1 row-cols-lg-2 mb-3">
                    <div className="col mb-3">
                      <input type="text"
                        style={{ color: 'white' }}
                        className="form-control"
                        name="first_name" id="first_name"
                        placeholder="First Name" required
                        value={message.firstName}
                        onChange={e => setMessage({ ...message, firstName: e.target.value })} />
                      <div className="invalid-feedback">
                        The field is required.
                      </div>
                    </div>
                    <div className="col mb-3">
                      <input type="text"
                        style={{ color: 'white' }}
                        className="form-control" name="last_name" id="last_name"
                        placeholder="Last Name" required
                        value={message.lastName}
                        onChange={e => setMessage({ ...message, lastName: e.target.value })} />
                      <div className="invalid-feedback">
                        The field is required.
                      </div>
                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-lg-2 mb-3">
                    <div className="col mb-3">
                      <input type="email"
                        style={{ color: 'white' }}
                        className="form-control p-3"
                        name="email" id="email"
                        placeholder="Email" required
                        value={message.email}
                        onChange={e => setMessage({ ...message, email: e.target.value })} />
                      <div className="invalid-feedback">
                        The field is required.
                      </div>
                    </div>
                    <div className="col mb-3">
                      <input type="text"
                        style={{ color: 'white' }}
                        className="form-control p-3" name="subject" id="subject"
                        placeholder="Subject" required
                        value={message.subject}
                        onChange={e => setMessage({ ...message, subject: e.target.value })} />
                      <div className="invalid-feedback">
                        The field is required.
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <textarea
                      style={{ color: 'white', borderColor: 'white' }}
                      className="form-control"
                      id="message"
                      name="message"
                      placeholder="Message"
                      value={message.message}
                      onChange={e => setMessage({ ...message, message: e.target.value })} />
                  </div>
                  <div className="mb-3">
                    <button
                      onClick={(e) => { e.preventDefault(); void sendMessage() }}
                      className="btn button rounded-0 button-outline accent-color fw-bold font-1 py-3 see-all-events-button">
                      Send Message
                    </button>
                  </div>
                </form>
                <p className="font-1 fw-bold" style={{ color: 'white' }}>
                  We will contact you back within 24 hours via email.
                </p>
              </div>
            </div>
            <div className="col p-0">
              <div className="d-flex flex-column">
                <h5 className="accent-color lh-1 ls-2 fw-bold font-1">Contact</h5>
                <h3 className="lh-1 fw-bold" style={{ color: 'white', fontSize: '48px' }} >Request Call Back</h3>
                <div className="description" style={{ color: 'white' }}>
                  Have questions or want to discuss your upcoming event? We're here to help! Simply drop
                  us an email at yohan@yoentertainment.events or give us a call at +1 236 877 2209.
                  Our dedicated team at Yo Entertainment is ready to assist you and provide the
                  personalized attention you deserve. Let's bring your vision to life together!

                </div>
                <div className="row row-cols-1 row-cols-lg-2" style={{ marginTop: 15 }}>
                  <div className="col mb-3">
                    <div className="d-flex align-items-center gap-3">
                      <i className="fa-solid fa-phone accent-color"></i>
                      <a href="tel:+94777770076" className="contact-link">
                        +94 077 7770 076 </a>
                    </div>
                  </div>
                  <div className="col mb-3">
                    <div className="d-flex align-items-center gap-3">
                      <i className="fa-solid fa-phone accent-color"></i>
                      <a href="tel:+12368772209" className="contact-link">
                        +1 236 877 2209</a>
                    </div>
                  </div>
                  <div className="col mb-3">
                    <div className="d-flex align-items-center gap-3">
                      <i className="fa-solid fa-envelope accent-color"></i>
                      <a href="email:info@yoentertainment.lk" className="contact-link">
                        info@yoentertainment.lk</a>
                    </div>
                  </div>
                  <div className="col mb-3">
                    <div className="d-flex align-items-center gap-3">
                      <i className="fa-solid fa-envelope accent-color"></i>
                      <a href="email:info@yoentertainment.lk" className="contact-link">
                        info@yoentertainment.ca</a>
                    </div>
                  </div>
                  <div className="col mb-3">
                    <div className="d-flex align-items-center gap-3">
                      <i className="fa-solid fa-location-dot accent-color"></i>
                      <a href="#" className="contact-link">
                        Colombo, Sri Lanka</a>
                    </div>
                  </div>
                  <div className="col mb-3">
                    <div className="d-flex align-items-center gap-3">
                      <i className="fa-solid fa-location-dot accent-color"></i>
                      <a href="#" className="contact-link">
                        Port Coquitlam, Canada</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FloatButton.BackTop 
          icon={<ArrowUpOutlined />}
          type="primary" 
          visibilityHeight={768}
          style={{ inset: 'auto 35px 100px auto', backgroundColor: '#fc8439', fontWeight: 'bold' }}
        />
      </section>
    </>
  );
}

const mapDispatchToProps = (dispatch: any): any => ({
  setError: (error: ErrorDto) => dispatch({ type: appActions.SET_ERROR, payload: error }),
  setLoading: (loading: boolean) => dispatch({ type: appActions.SET_LOADING, payload: loading })
});

export default connect(null, mapDispatchToProps)(Home);

