import { useState } from "react";
import { LatestEventDto } from "../../models/events/LatestEventDto";

interface RecentEventsProps {
  recentEvent: LatestEventDto;
}

const RecentEvents: React.FC<RecentEventsProps> = ({ recentEvent }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const playVideo = () => {
    setIsVideoPlaying(true);
  };
  
  return (
    <section className="section" id="about" style={{ color: 'white' }} >
      <div className="r-container">
        <div className="d-flex flex-column gap-3 ">
          <div className="row">
              <h5 className="accent-color lh-1 ls-2 fw-bold font-1">Latest Event</h5>
              <h3 className="lh-1 fw-bold" style={{ fontSize: '48px' }}>{recentEvent.title}</h3>
          </div>
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col col-lg-7 mb-3">
              {!isVideoPlaying &&
              <div className="shadow d-flex justify-content-center align-items-center video-container latest-event"
                style={{ backgroundImage: `url(${recentEvent.coverUri})` }}
                data-url="https://www.youtube.com/embed/-xRB3d1uVEo"
                data-title="Video Embedded">
                <div className="img-overlay"></div>
                <div>
                  <button className="bg-transparent shadow accent-color video-btn video-btn-small" onClick={playVideo}>
                    <i className="fa-solid fa-play" style={{ marginLeft: '5px' }}></i>
                  </button>
                </div>
              </div>
              }
              {
                isVideoPlaying &&
                <iframe
                style={{ aspectRatio: '16/9' }}
                width="100%"
                height="100%"
                src={recentEvent.youtubeUri}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              }
              <div className="latest-overlay"></div>
            </div>
            <div className="col col-lg-5 mb-3">
              <div className="d-flex flex-column ps-lg-4">
                <h5 >{recentEvent.subtitle}</h5>
                <div className='descriptions'>
                  {recentEvent.description}
                </div>
                <div className="row row-cols-1 row-cols-lg-2" style={{ marginTop: 15 }}>
                  <div className="col mb-3">
                    <div className="d-flex flex-column text-center">
                      <h3 className="accent-color font-1 fw-bold m-0">{recentEvent.eventAttribute1Value}</h3>
                      <p className="font-1">{recentEvent.eventAttribute1Label}</p>
                    </div>
                  </div>
                  <div className="col mb-3">
                    <div className="d-flex flex-column text-center">
                      <h3 className="accent-color font-1 fw-bold m-0">{recentEvent.eventAttribute2Value}</h3>
                      <p className="font-1">{recentEvent.eventAttribute2Label}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RecentEvents;