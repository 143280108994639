import { FiledValidationInfo } from "./FiledValidationInfo";

export class ReservationValidationState{
    ticketTypeId: FiledValidationInfo;
    quantity: FiledValidationInfo;
    name: FiledValidationInfo;
    email: FiledValidationInfo;
    phone: FiledValidationInfo;
    address: FiledValidationInfo;
    identificationField: FiledValidationInfo;
    status: boolean;

    constructor(){
        this.ticketTypeId = new FiledValidationInfo();
        this.quantity = new FiledValidationInfo();
        this.name = new FiledValidationInfo();
        this.email = new FiledValidationInfo();
        this.phone = new FiledValidationInfo();
        this.address = new FiledValidationInfo();
        this.identificationField = new FiledValidationInfo();
        this.status = true;
    }
}
